(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/poy-score-calculator/assets/javascripts/poy-score-calculator.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/poy-score-calculator/assets/javascripts/poy-score-calculator.js');
"use strict";


const {
  PokerPaper,
  DialogContextual
} = svs.poker_react.components;
const {
  ControlGroup,
  Inputv2: Input,
  Form
} = svs.ui.reactForm;
const {
  useState,
  useEffect
} = React;
const calculateScore = (n, p, b) => Math.round(10 * Math.sqrt(n / p) * (0.5 + 0.5 * Math.log(b)));
const twentyPercent = 0.2;
const PoyScoreCalculator = () => {
  const [buyInCost, setBuyInCost] = useState('');
  const [nPlayers, setNPlayers] = useState('');
  const [finishingPosition, setFinishingPosition] = useState('');
  const [calculatedScore, setCalculatedScore] = useState(null);

  const dialog = 'Rankingpoängen räknas fram med formeln 10*[Sqrt(N/P)]*[0.5+0.5*Ln(B)] där N = Deltagare i turneringen, P = Placering i turneringen och B = Inköp exkl. turneringsavgift. Observera att endast de bästa 20% får poäng.';
  useEffect(() => {
    if (nPlayers && buyInCost && finishingPosition && parseInt(finishingPosition, 10) / parseInt(nPlayers, 10) <= twentyPercent) {
      setCalculatedScore("".concat(calculateScore(parseInt(nPlayers, 10), parseInt(finishingPosition, 10), parseInt(buyInCost, 10))));
    } else {
      setCalculatedScore(null);
    }
  }, [nPlayers, finishingPosition, buyInCost]);
  const validateInput = (value, stateFunction) => {
    if (value && parseInt(value, 10) || value === '') {
      stateFunction(parseInt(value, 10) ? parseInt(value, 10) : '');
    }
  };
  return React.createElement("div", {
    className: "poy-score-wrapper margin-top-4"
  }, React.createElement("h2", {
    className: "h2 center-text poy-score-calculator-header"
  }, React.createElement("span", null, "R\xE4kna ut dina rankingpo\xE4ng"), React.createElement(DialogContextual, {
    dialog: dialog
  })), React.createElement("p", {
    className: "center-text"
  }, "Fyll i formul\xE4ret och ta reda p\xE5 vilken po\xE4ng du f\xE5r vid olika placeringar."), React.createElement(PokerPaper, {
    className: "poy-score-calculator"
  }, React.createElement(Form, null, React.createElement(ControlGroup, {
    v2: true
  }, React.createElement(Input, {
    autoComplete: "off",
    inverted: true,
    label: "Ink\xF6p",
    name: "buyIn",
    onChange: e => validateInput(e.target.value, setBuyInCost),
    type: "text",
    value: buyInCost
  }), React.createElement(Input, {
    autoComplete: "off",
    inverted: true,
    label: "Antal spelare",
    name: "nPlayers",
    onChange: e => validateInput(e.target.value, setNPlayers),
    type: "text",
    value: nPlayers
  }), React.createElement(Input, {
    autoComplete: "off",
    inverted: true,
    label: "Slutplacering",
    name: "finishingPosition",
    onChange: e => validateInput(e.target.value, setFinishingPosition),
    type: "text",
    value: finishingPosition
  }))), React.createElement("div", {
    className: "calctulated-score-result"
  }, React.createElement("p", {
    className: "center-text final-score-header"
  }, "Rankingpo\xE4ng"), React.createElement("p", {
    className: "center-text final-score h3"
  }, calculatedScore ? calculatedScore : '-'))));
};
setGlobal('svs.poker_react.components.PoyScoreCalculator', PoyScoreCalculator);

 })(window);